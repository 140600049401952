import React from 'react';
type Props = {
    sectionId: string;
    children?: React.ReactNode;

}
const Section = ({ sectionId, children }: Props) => {
    return (
        <>
        <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id={sectionId}>
            <div className="w-100">
                {children}
            </div>
        </section>
        <hr/>
        </>
    )
}
export default Section;
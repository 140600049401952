import React from 'react';
import NavbarLink from './NavbarLink/index'
import Photo from '../../assets/images/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg bg-primary navbar-dark fixed-top" id="sideNav">
            <NavbarLink to="aboutMe" className="navbar-brand" >
                <span className="d-block d-lg-none">Pedro Rodrigues</span>
                <span className="d-none d-lg-block">
                    <img src={Photo} alt="Minha Foto" className="img-fluid img-profile rounded-circle mx-auto mb-2" />
                </span>
            </NavbarLink>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <ul className="navbar-nav">
                    <li className="nav-item"><NavbarLink to="aboutMe" className="nav-link"><FontAwesomeIcon icon="info" size="lg" className="icon-navbar" />Sobre Mim</NavbarLink></li>
                    <li className="nav-item"><NavbarLink to="experience" className="nav-link"><FontAwesomeIcon icon="briefcase" size="lg" className="icon-navbar" />Experiência</NavbarLink></li>
                    <li className="nav-item"><NavbarLink to="formation" className="nav-link"><FontAwesomeIcon icon="user-graduate" size="lg" className="icon-navbar" />Formação</NavbarLink></li>
                    <li className="nav-item"><NavbarLink to="skills" className="nav-link"><FontAwesomeIcon icon="lightbulb" size="lg" className="icon-navbar" />Habilidades</NavbarLink></li>
                    <li className="nav-item"><NavbarLink to="contact" className="nav-link"><FontAwesomeIcon icon="envelope-open-text" size="lg" className="icon-navbar" />Contato</NavbarLink></li>
                    <li className="nav-item"><NavbarLink to="#" className="nav-link disabled"><FontAwesomeIcon icon="palette" size="lg" className="icon-navbar" />Portifólio(Breve)</NavbarLink></li>
                </ul>
            </div>
        </nav>
    )
}
export default Navbar;
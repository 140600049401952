import React from 'react';
import AboutMe from './sections/AboutMe'
import Experience from './sections/Experience'
import Formation from './sections/Formation'
import Skills from './sections/Skills'
import ContactForm from './sections/ContactForm'

const Home = () => (
    <>
        <AboutMe />
        <Experience />
        <Formation />
        <Skills />
        <ContactForm />
    </>
)

export default Home;
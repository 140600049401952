import { ErrorMessage } from "@hookform/error-message";
import { formContactPost } from "core/utils/request";
import {
    dismissAllToast,
    dismissToast, sendFormError,
    sendFormSuccess,
    sendingForm
} from "core/utils/toast";
import React from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Section from "../../../core/components/Section";

type FormState = {
    name: string;
    email: string;
    subject: string;
    message: string;
};
const ContactForm = () => {
    const { register, handleSubmit, errors } = useForm<FormState>();

    const onSubmit = (data: FormState) => {
        var postResponseStatusForm: Number;
        dismissAllToast();
        sendingForm(1, "🚀 Enviando mensagem...");
        formContactPost({ data })
            .then((response) => (postResponseStatusForm = response.status))
            .finally(() => {
                dismissToast(1);
                postResponseStatusForm === 200
                    ? sendFormSuccess(2, "✅ Mensagem enviada com sucesso!")
                    : sendFormError(3, "❌ Erro ao enviar a mensagem!");
            });
    };

    return (
        <Section sectionId="contact">
            <h3 className="mb-4">Entre em contato</h3>
            <form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
            >
                <label htmlFor="name">Nome *</label>
                <input
                    name="name"
                    type="text"
                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                    placeholder="Nome"
                    ref={register({
                        required: "Campo obrigatório",
                        minLength: {
                            value: 2,
                            message: "No minímo 2 caracters",
                        },
                        maxLength: {
                            value: 60,
                            message: "No máximo 60 caracters",
                        },
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => (
                        <div className="invalid-feedback">
                            <p>{message}</p>
                        </div>
                    )}
                />
                <label htmlFor="email">E-mail *</label>
                <input
                    name="email"
                    type="text"
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    placeholder="E-mail"
                    ref={register({
                        required: "Campo obrigatório",
                        pattern: {
                            value: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                            message: "E-mail inválido",
                        },
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                        <div className="invalid-feedback">
                            <p>{message}</p>
                        </div>
                    )}
                />
                <label htmlFor="subject">Assunto *</label>
                <input
                    name="subject"
                    type="text"
                    className={`form-control ${errors.subject ? "is-invalid" : ""}`}
                    placeholder="Assunto"
                    ref={register({
                        required: "Campo obrigatório",
                        maxLength: {
                            value: 40,
                            message: "No máximo 60 caracters",
                        },
                        minLength: {
                            value: 5,
                            message: "No minímo 5 caracters",
                        },
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name="subject"
                    render={({ message }) => (
                        <div className="invalid-feedback">
                            <p>{message}</p>
                        </div>
                    )}
                />
                <label htmlFor="message">Mensagem *</label>
                <textarea
                    className={`form-control ${errors.message ? "is-invalid" : ""}`}
                    name="message"
                    cols={30}
                    rows={8}
                    ref={register({
                        required: "Campo obrigatório",
                        minLength: {
                            value: 5,
                            message: "No minímo 5 caracters",
                        },
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => (
                        <div className="invalid-feedback">
                            <p>{message}</p>
                        </div>
                    )}
                />
                <div className="d-flex justify-content-center">
                    <button className="submit-button btn btn-primary btn-lg" type="submit">
                        ENVIAR
                    </button>
                </div>
            </form>
            <ToastContainer
                position="bottom-right"
                autoClose={6000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Section>
    );
};

export default ContactForm;

import { toast } from 'react-toastify';

export const dismissToast = (toastId: number) => toast.dismiss(toastId);

export const dismissAllToast = () => toast.dismiss();

export const sendingForm = (toastId: number, message: string) => {
    toast.info(message, {
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 1,
        toastId
    });
};

export const sendFormSuccess = (toastId: number, message: string) => {
    toast.success(message, {
        position: "bottom-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId
    });
};

export const sendFormError = (toastId: number, message: string) => {
    toast.error(message, {
        position: "bottom-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId
    });
};

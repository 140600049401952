import React from 'react';
import Section from '../../../core/components/Section';

const Formation = () => (
    <Section sectionId="formation" >
        <div>
            <h3 className="mb-4">FORMAÇÃO</h3>
            <div className="resume-content ">
                <div className="d-flex justify-content-between">
                    <h4 className="mb-0">Centro Universitário Planalto do Distrito Federal</h4>
                    <div className="resume-date text-md-right">
                        <span className="text-primary">2019/1 - 2020/2</span>
                    </div>
                </div>
                <div className="subheading mb-4">
                    SUPERIOR
                    </div>
                <p className="text-justify">Análise e Desenvolvimento de Sistema - ADS</p>
            </div>
        </div>
    </Section>
)
export default Formation;
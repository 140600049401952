import React from 'react';
import Section from '../../../core/components/Section';

const Experience = () => (
    <Section sectionId="experience" >
        <div>
            <h3 className="mb-4">Experiência</h3>
            <div className="resume-content ">
                <hr />
                <div className="d-flex justify-content-between">
                    <h4 className="mb-0">Analísta N2 (helpdesk)</h4>
                    <div className="resume-date text-md-right">
                        <span className="text-primary">Novembro 2021 - Atual</span>
                    </div>
                </div>
                <div className="subheading mb-4">
                    <a href="https://www.castgroup.com.br/pt/" className="text-decoration-none" >CAST</a>
                </div>
                <p className="text-justify">Suporte a usuário presencial ou remoto atráves de chamadas telefônicas, e-mails, mensagens instantâneas ou tickets. Resolução de problemas relacionados a hardware, software e rede usuários. Gerenciar e monitorar (Zabbix) diversos sistemas e aplicações, atribuir demandas para outras equipes.</p>
            </div>
            <div className="resume-content ">
                <hr />
                <div className="d-flex justify-content-between">
                    <h4 className="mb-0">Analísta de redes e comunicação</h4>
                    <div className="resume-date text-md-right">
                        <span className="text-primary">Dezembro 2018 - Novembro 2021</span>
                    </div>
                </div>
                <div className="subheading mb-4">
                    <a href="https://www.3corp.com.br" className="text-decoration-none" >3Corp Technology</a>
                </div>
                <p className="text-justify">Auxílio no desenvolvimento das operações e resoluções de problemas, prestando um serviço de atendimento ao cliente em diversos orgãos como
                    <span className="text-primary ml-1">AGU</span>,
                    <span className="text-primary ml-1">SEBRAE</span> e
                    <span className="text-primary ml-1">BANCO DO BRASIL</span>, possuindo assim sólida experiência em suporte telefônico e remoto, além de monitoramento e manutenção em diversos tipos de equipamentos com PABX, controladora, switch e servidores.
                </p>
            </div>
        </div>
    </Section>
)
export default Experience;
import React from 'react';
import Section from '../../../core/components/Section';
import { ReactComponent as Github } from '../../../core/assets/images/github.svg';
import { ReactComponent as Linkedin } from '../../../core/assets/images/linkedin.svg';
import { ReactComponent as Whatsapp } from '../../../core/assets/images/whatsapp.svg';
import { ReactComponent as Map } from '../../../core/assets/images/map-marker.svg';
import { ReactComponent as Phone } from '../../../core/assets/images/phone.svg';
import { ReactComponent as Envelope } from '../../../core/assets/images/envelope.svg';

const AboutMe = () => {
    return (
        <Section sectionId="aboutMe" >
            <div className="mb-5">
                <h1 >Pedro <span className="pl-2 text-primary">Rodrigues</span></h1>
                <hr className="m-0"></hr>
                <div className="subheading flex-wrap">
                    <span><Map />Brasília, DF</span>
                    <a href="tel:061983817104" className="text-decoration-none"><Phone />(61) 98381-7104</a>
                    <a href="mailto:phsr2001@gmail.com" className="text-decoration-none"><Envelope />phsr2001@gmail.com</a>
                </div>
            </div>
            <p className="mb-4 text-justify lead">Busco a cada dia evoluir no mundo da tecnologia para atingir meus objetivos profissionais. Tenho facilidade com adequação ao local de trabalho e novas tecnologias, busco sempre foco no trabalho, na qualidade e na satisfação dos clientes e usuário. Tenho boa comunicação, escrita, proatividade e empatia.
        </p>
            <div className="social-icons">
                <a className="social-icon" target='_blank' rel="noreferrer" href="https://www.linkedin.com/in/ophsr/" title="Meu Linkedin">
                    <Linkedin />
                </a>
                <a className="social-icon" target='_blank' rel="noreferrer" href="https://github.com/ophsr" title="Meu GitHub">
                    <Github />
                </a>
                <a className="social-icon" target='_blank' rel="noreferrer" href="https://api.whatsapp.com/send?phone=5561983817104&text=Ol%C3%A1%2C%20Pedro!" title="Meu WhatsApp">
                    <Whatsapp />
                </a>
            </div>
        </Section>
    )
}

export default AboutMe;
import React from 'react';

type Props = {
    name: string;
    children?: React.ReactNode;

}
const ListIcon = ({ children, name }: Props) => (
    <li className="list-inline-item text-center">
        {children}
        <p className="lead m-0">{name}</p>
    </li>
)

export default ListIcon;
import React from 'react';
import Section from '../../../core/components/Section';
import ListIcon from '../../../core/components/listIcon/';
import { ReactComponent as Git } from '../../../core/assets/images/git.svg'
import { ReactComponent as Html } from '../../../core/assets/images/html.svg';
import { ReactComponent as Css } from '../../../core/assets/images/css.svg'
import { ReactComponent as Bootstrap } from '../../../core/assets/images/bootstrap.svg'
import { ReactComponent as Csharp } from '../../../core/assets/images/csharp.svg'
import { ReactComponent as Java } from '../../../core/assets/images/java.svg'
import { ReactComponent as MySql } from '../../../core/assets/images/mysql.svg'
import { ReactComponent as ReactIcon } from '../../../core/assets/images/react.svg'
import { ReactComponent as Spring } from '../../../core/assets/images/spring.svg'

const Skills = () => (
    <Section sectionId="skills" >
        <div>
            <h3 className="mb-4">HABILIDADES</h3>
            <div className="resume-content">
                <div className="d-flex justify-content-between">
                    <h5 className="mb-0">linguagens de programação &amp; ferramentas</h5>
                </div>
                <ul className="list-inline dev-icons">
                    <ListIcon name="Git"><Git /></ListIcon>
                    <ListIcon name="Java"><Java /></ListIcon>
                    <ListIcon name="Csharp"><Csharp /></ListIcon>
                    <ListIcon name="Spring Boot"><Spring /></ListIcon>
                    <ListIcon name="MySQL"><MySql /></ListIcon>
                    <ListIcon name="React"><ReactIcon /></ListIcon>
                    <ListIcon name="Bootstrap"><Bootstrap /></ListIcon>
                    <ListIcon name="HTML"><Html /></ListIcon>
                    <ListIcon name="CSS"><Css /></ListIcon>
                </ul>
            </div>
        </div>
    </Section>
)
export default Skills;
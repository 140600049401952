import React from 'react';
import './core/assets/js/scripts.js';
import './core/assets/styles/styles.scss';
import Routes from './Routes';
import { Helmet } from "react-helmet";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faInfo,faBriefcase,faUserGraduate,faLightbulb,faEnvelopeOpenText, faPalette} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faInfo,faBriefcase,faUserGraduate,faLightbulb,faEnvelopeOpenText,faPalette)

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Site e portfolio de Pedro Rodrigues, contendo informações sobre ele e seu trabalho." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#011C26" />
        <meta name="keywords" content="Site, Portfolio, Profissional, Desenvolvedor" />
        <meta name="author" content="Pedro Rodrigues" />
        <title>Pedro Rodrigues - Site e Portfolio</title>
      </Helmet>
      <Routes />
    </>
  )
}

export default App;

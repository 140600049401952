import React from 'react';
import { Link } from 'react-scroll';

type Props = {
    to: string;
    className: string;
    children?: React.ReactNode;
}
const NavbarLink = ({ to, children, className }: Props) => (
    <Link
        className={className}
        activeClass="active"
        to={to}
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
    >{children}</Link>
)

export default NavbarLink;
import axios from 'axios';

const FORMSPREE_ACTION_URL = process.env.REACT_APP_HOST

type params = {
    data: object | string;
}

export const formContactPost = ({ data }: params) => {
    return axios({
        method: 'POST',
        url: `${FORMSPREE_ACTION_URL}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        data
    });
}